/*
Reset

Normalization of HTML elements, manually forked from Normalize.css to remove
styles targeting irrelevant browsers while applying new styles.
*/

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgb(0, 0, 0);
    height: 100%;
}

body.legacy-non-scrollable {
    margin: 0;
    overflow: hidden;
    padding: 33px 0 78px;
    height: auto;
}

body.legacy-print {
    font-family: 'Open Sans', sans-serif;
}

body {
    margin: 0;
    line-height: 1.5;
    text-align: left;
    background-color: #f0f0f0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: .0128em;
    height: 100%;
}

#_legacy{
    flex:1 1 auto;
    width: 100%;
    overflow-x: visible;
}

#recentHistoryItems {
    max-height: calc(80vh - 3.75rem);
    overflow-y: auto;
}

@media print {

    body {
        display: block
    }

    body #_legacy {
        display: block !important;
        overflow: visible  !important;
    }

    body #_legacy .netScrollable .dialogBody {
        height: auto  !important;
        overflow: visible  !important;
    }
}